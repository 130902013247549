import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
  Adjustment,
  AssignOrderTypeEvent,
  Order,
  OrderAction,
  OrderInitiateMergeEvent,
  OrderItem,
  OrderPayment,
  OrderStatus,
  OrderTypeCode,
  Resource,
  Table,
  TableStatus,
} from '@oolio-group/domain';
import { useMutation, useReactiveVar } from '@apollo/client/react/hooks';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/core';
import { MaterialTopTabScreenProps } from '@react-navigation/material-top-tabs';
import { postSalesObservableForLogin } from '../../../../../hooks/app/usePostSalesNavigation';
import { ORDERS_SAVE } from '../../../../../hooks/app/orders/graphql';
import { refetchOrderObservable } from '../../../../../hooks/app/orders/ordersObservableUtils';
import usePOSUserAuthorization from '../../../../../hooks/app/users/usePOSUserAuthorization';
import { useSession } from '../../../../../hooks/app/useSession';
import useBehaviorSubjectEffect from '../../../../../hooks/app/useSubjectEffect';
import useBehaviorSubjectState from '../../../../../hooks/app/useSubjectState';
import { useNotification } from '../../../../../hooks/Notification';
import { usePrinting } from '../../../../../hooks/PrintingProvider';
import { useTimeout } from '../../../../../hooks/useTimeout';
import { ordersReceivedViaPollingAt } from '../../../../../state/cache';
import { sortTablesByName } from '../../../../../utils/TableHelper';
import {
  editModeController,
  mergeTableSelectionsController,
  orderIdSelectionController,
  sectionIdController,
  TableAction,
  tableActionController,
  tableSelectionController,
  tableStatsVisibilityController,
  transferSelectionsController,
  unsavedTableController,
  transferItemsVisibilityController,
} from './floorViewObservables';
import { pickBy, identity, uniqBy } from 'lodash';
import PanView from './PanView/PanView';
import DraggableTableIcon, {
  calculateTablePositionX,
  calculateTablePositionY,
} from './Tables/DraggableTableIcon';
import FloorViewActions from './Footer/FloorViewActions';
import FloorViewLegend from './Footer/FloorViewLegend';
import { PickerModal } from '../../../../../components/Modals/Picker/Picker';
import SetGuestModal from '../../../../../components/Modals/SetGuest/SetGuestModal';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationDialog';
import styles from '../FloorView.styles';
import theme from '../../../../../common/default-theme';
import { useBatchedCallback } from '../../../../../hooks/useBatchedCallback';
import { useCart } from '../../../../../hooks/orders/useCart';
import { useTablesData } from '../../../../../hooks/app/tables/useTablesData';
import { FEATURES } from '../../../../../constants';
import { analyticsService } from '../../../../../analytics/AnalyticsService';

const MERGEABLE_TABLE_STATUS_FOR_2 = [
  TableStatus.DONE_SOON,
  TableStatus.OCCUPIED,
];

export type SectionViewParam = MaterialTopTabScreenProps<
  {
    SectionView: { id: string };
  },
  'SectionView'
>;

const ListTableIcons = ({
  list,
  renderer,
}: {
  list: Table[];
  renderer: (table: Table, index: number) => React.ReactNode;
}) => {
  return <>{list.map(renderer)}</>;
};
export const MemoListTableIcons = React.memo(ListTableIcons);

export interface TransferTableSelection {
  fromTable?: Table;
  fromOrderIndex?: number;
  toTable?: Table;
}

export interface MergeTableSelection {
  // handles multi order tables for now
  fromTable?: Table;
  // TODO: merging orders of two non multi order tables
  //toTable?: Table;
}

const SectionView: React.FC = () => {
  const navigation = useNavigation();
  const { printBill } = usePrinting();
  const { translate } = useTranslation();
  const [{ deviceProfile }] = useSession();
  const safeHeight = theme.useSafeHeight();
  const { canI } = usePOSUserAuthorization();
  const { showModal, closeModal } = useModal();
  const { params } = useRoute<SectionViewParam['route']>();
  const { showNotification, closeAllNotifications } = useNotification();
  const section = deviceProfile?.sections?.find(s => s.id === params.id);
  const {
    setCartParams,
    resetCart,
    updateCart,
    clearPriorPendingEvents,
    openOrderCart,
  } = useCart();

  const [draggingTable, setDraggingTable] = useState<Table>();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isTransferItemsEnabled, setIsTransferItemsEnabled] = useState(false);

  const { value: transferSelections, setValue: setTransferSelections } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useBehaviorSubjectState<TransferTableSelection | any>(
      transferSelectionsController,
    );
  const { setValue: setSectionId } =
    useBehaviorSubjectState<string>(sectionIdController);
  const { value: tableAction, setValue: setTableAction } =
    useBehaviorSubjectState<TableAction>(tableActionController);
  const { setValue: setSelectedTable, value: selectedTable } =
    useBehaviorSubjectState<Table | null>(tableSelectionController);
  const { setValue: setSelectedOrderId } = useBehaviorSubjectState<string>(
    orderIdSelectionController,
  );
  const { value: unsavedTables, setValue: setUnsavedTables } =
    useBehaviorSubjectState<Table[]>(unsavedTableController);
  const { value: isEditMode } =
    useBehaviorSubjectState<boolean>(editModeController);
  const { setValue: showStatsTablePanel } = useBehaviorSubjectState<boolean>(
    tableStatsVisibilityController,
  );
  const { setValue: setMergeTableOption, value: mergeTableOption } =
    useBehaviorSubjectState(mergeTableSelectionsController);

  const { setValue: setIsTransferItemsVisible } =
    useBehaviorSubjectState<boolean>(transferItemsVisibilityController);

  const ordersUpdatedThroughPolling = useReactiveVar<number>(
    ordersReceivedViaPollingAt,
  );

  const nonDineInOrderTypes = useMemo(
    () =>
      deviceProfile?.orderTypes?.filter(
        orderType => orderType.code !== OrderTypeCode.DINE_IN,
      ) ?? [],
    [deviceProfile],
  );

  const dineInOrderType = useMemo(
    () =>
      deviceProfile?.orderTypes?.find(
        orderType => orderType.code === OrderTypeCode.DINE_IN,
      ),
    [deviceProfile],
  );

  const clearSelectedTable = useCallback(() => {
    setSelectedTable(null);
  }, [setSelectedTable]);

  useEffect(() => {
    if (tableAction && tableAction == TableAction.DEFAULT) {
      setTransferSelections({});
    }
  }, [tableAction, setTransferSelections]);

  useEffect(() => {
    (async () => {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.TRANSFER_ITEMS,
      );
      setIsTransferItemsEnabled(isEnabled);
    })();
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (params?.id) {
        setSectionId(params.id);
      }
    }, [params.id, setSectionId]),
  );
  useFocusEffect(
    useCallback(
      () => () => {
        // clean up when navigate away
        showStatsTablePanel(false);
        setSelectedTable(null);
        setSelectedOrderId('');
        if (!transferSelections) {
          setTableAction(TableAction.DEFAULT);
        }
      },
      [
        showStatsTablePanel,
        setSelectedTable,
        setSelectedOrderId,
        setTableAction,
        transferSelections,
      ],
    ),
  );

  const { getTablesData, tableStatusMap, tableOrdersMap } = useTablesData();

  const refreshTableDataMap = useCallback(() => {
    getTablesData();
    if (selectedTable?.id && !tableOrdersMap[selectedTable?.id]) {
      clearSelectedTable();
      showStatsTablePanel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTablesData, selectedTable, clearSelectedTable, showStatsTablePanel]);

  const delayRefreshTableDataMap = useTimeout(refreshTableDataMap);

  useFocusEffect(refreshTableDataMap);

  useEffect(() => {
    if (ordersUpdatedThroughPolling) {
      delayRefreshTableDataMap.start(2000);
    }
  }, [ordersUpdatedThroughPolling, delayRefreshTableDataMap]);

  useBehaviorSubjectEffect(refetchOrderObservable, refreshTableDataMap);
  useFocusEffect(
    useCallback(() => {
      if (params?.id) {
        setSectionId(params.id);
      }
    }, [params?.id, setSectionId]),
  );

  const onCompleteMergeOrders = useCallback(() => {
    setTableAction(TableAction.DEFAULT);
    showNotification({
      success: true,
      message: translate('tableFloorView.mergeActionCompleteMessage'),
    });
    refetchOrderObservable.next({ timestamp: Date.now() });
    closeModal();
  }, [closeModal, setTableAction, showNotification, translate]);

  const [saveMergedOrders] = useMutation(ORDERS_SAVE, {
    onCompleted: onCompleteMergeOrders,
  });

  const inProgressOrdersForSection = useMemo<Order[]>(() => {
    return Object.values(tableOrdersMap)
      .flat()
      .filter(order => order?.table?.section?.id === params?.id);
  }, [params?.id, tableOrdersMap]);

  const allTables = useMemo(
    () =>
      sortTablesByName(section?.tables ?? []).map(
        table =>
          ({
            ...table,
            status: tableStatusMap[table.id],
            section: {
              id: params?.id,
            },
          } as Table),
      ),
    [section?.tables, tableStatusMap, params?.id],
  );

  const positionedTables = useMemo<Table[]>(
    () =>
      allTables.filter(
        table =>
          typeof table.position?.top === 'number' &&
          typeof table.position?.left === 'number',
      ),
    [allTables],
  );
  const unPositionedAllTables = useMemo<Table[]>(
    () =>
      allTables.filter(
        table =>
          typeof table.position?.top !== 'number' ||
          typeof table.position?.left !== 'number',
      ),
    [allTables],
  );
  const isUnPositionedAllTables = useMemo<boolean>(
    () => unPositionedAllTables.length === allTables.length,
    [allTables, unPositionedAllTables],
  );

  const addExtraUnsavedGridTable = useCallback(
    (pendingTable: Table[]) => {
      if (!isUnPositionedAllTables) return;
      if (pendingTable?.length === allTables.length) return;
      const tableWithGridPosition = allTables
        .filter(table => !pendingTable.find(t => t.id === table.id))
        .map((table, index) => ({
          ...table,
          position: {
            top: calculateTablePositionY(index),
            left: calculateTablePositionX(index),
          },
        }));
      setUnsavedTables([...pendingTable, ...tableWithGridPosition]);
    },
    [isUnPositionedAllTables, setUnsavedTables, allTables],
  );

  const goToPaymentScreen = useCallback(
    async (tableID: string, orderIndex: number) => {
      const orders = tableOrdersMap[tableID];
      const currentOrder = orders?.[orderIndex];
      // if there are any payments and the amount due is 0 then we can not pay , we can complete order
      if (
        currentOrder &&
        currentOrder?.payments?.length &&
        currentOrder?.amountDue === 0
      ) {
        showNotification({
          error: true,
          message: translate('payment.payZeroError'),
        });
      } else if (currentOrder) {
        clearPriorPendingEvents();
        await setCartParams(orders[orderIndex].id, undefined, undefined, true);
        openOrderCart(orders[orderIndex].id);
        setTableAction(TableAction.DEFAULT);
        navigation.navigate('Payment', {
          orderId: orders[orderIndex]?.id,
        });
      }
    },
    [
      clearPriorPendingEvents,
      navigation,
      setCartParams,
      setTableAction,
      showNotification,
      tableOrdersMap,
      translate,
      openOrderCart,
    ],
  );

  const onPressPrintReceipt = useCallback(
    async (tableID: string, orderIndex: number) => {
      const orders = tableOrdersMap[tableID];
      if (orders?.[orderIndex]) {
        clearPriorPendingEvents();
        await setCartParams(orders[orderIndex].id, undefined, undefined, true);
        const onPrintResult = await printBill(orders[orderIndex]);
        if (onPrintResult && Object.keys(onPrintResult)?.length > 0) {
          if (onPrintResult.success) {
            await updateCart(OrderAction.ORDER_PRINT);
            await updateCart(OrderAction.ORDER_SAVE);
            refreshTableDataMap();
          } else {
            showNotification(onPrintResult);
          }
        }
        setTableAction(TableAction.DEFAULT);
      }
    },
    [
      clearPriorPendingEvents,
      printBill,
      refreshTableDataMap,
      setCartParams,
      setTableAction,
      showNotification,
      tableOrdersMap,
      updateCart,
    ],
  );

  const onSubmitTable = useCallback(
    async (guestCount: number | undefined, table?: Table) => {
      if (guestCount === 0) return;
      const newOrderId = await resetCart();
      updateCart<AssignOrderTypeEvent>(OrderAction.ORDER_ASSIGN_ORDER_TYPE, {
        orderTypeId: dineInOrderType?.id as string,
        orderTypeName: dineInOrderType?.name as string,
      });

      const params = pickBy(
        {
          tableId: table?.id,
          tableName: table?.name,
          sectionId: section?.id,
          sectionName: section?.name,
          guestCount,
        },
        identity,
      );
      updateCart(OrderAction.ORDER_ASSIGN_TABLE, params);

      navigation.navigate('TakeOrder', {
        id: newOrderId,
        orderType: dineInOrderType?.id,
        table: table?.id,
        isExisting: false,
      });
      closeModal();
      clearSelectedTable();
    },
    [
      resetCart,
      updateCart,
      dineInOrderType?.id,
      dineInOrderType?.name,
      section?.id,
      section?.name,
      navigation,
      closeModal,
      clearSelectedTable,
    ],
  );

  const showSetGuestModal = useCallback(
    (table: Table) => {
      if (!table.status || table.status === TableStatus.AVAILABLE) {
        postSalesObservableForLogin.next(false);
        showModal(
          <SetGuestModal
            onSubmit={onSubmitTable}
            closeModal={closeModal}
            table={table}
          />,
        );
      }
    },
    [showModal, closeModal, onSubmitTable],
  );

  const transferTable = useBatchedCallback(
    async (transferTableSelections: Required<TransferTableSelection>) => {
      const { fromTable, toTable, fromOrderIndex } = transferTableSelections;
      const ordersToTransfer = tableOrdersMap[fromTable?.id || ''];
      if (ordersToTransfer?.[fromOrderIndex]) {
        clearPriorPendingEvents();
        await setCartParams(
          ordersToTransfer?.[fromOrderIndex].id,
          ordersToTransfer?.[fromOrderIndex].orderType.id,
          ordersToTransfer?.[fromOrderIndex].table.id,
          true,
        );
        updateCart(OrderAction.ORDER_ASSIGN_TABLE, {
          tableId: toTable?.id,
          tableName: toTable?.name,
          sectionId: section?.id,
          sectionName: section?.name,
          guestCount: ordersToTransfer?.[fromOrderIndex]?.table.guestCount,
        });
        await updateCart(OrderAction.ORDER_SAVE);
        setTableAction(TableAction.DEFAULT);
        showNotification({
          success: true,
          message: translate('tableFloorView.transferSuccessful'),
        });
        delayRefreshTableDataMap.start(100);
      }
    },
    [
      clearPriorPendingEvents,
      delayRefreshTableDataMap,
      section?.id,
      section?.name,
      setCartParams,
      setTableAction,
      showNotification,
      tableOrdersMap,
      translate,
      updateCart,
    ],
  );

  const handleOnPressPrint = useCallback(
    (table: Table, orderIndex: number) => {
      if (
        !table.status ||
        (table.status &&
          (table.status == TableStatus.AVAILABLE ||
            table.status === TableStatus.SEATED))
      ) {
        showNotification({
          error: true,
          message: translate('tableFloorView.printOrderWarning'),
        });
        setTableAction(TableAction.DEFAULT);
      } else {
        onPressPrintReceipt(table.id, orderIndex);
        clearSelectedTable();
      }
    },
    [
      onPressPrintReceipt,
      setTableAction,
      showNotification,
      translate,
      clearSelectedTable,
    ],
  );

  const handleOnPressPay = useCallback(
    (table: Table, orderIndex: number) => {
      if (
        !table.status ||
        (table.status &&
          (table.status == TableStatus.AVAILABLE ||
            table.status === TableStatus.SEATED))
      ) {
        showNotification({
          error: true,
          message: translate('tableFloorView.tableActionWarning'),
        });
        setTableAction(TableAction.DEFAULT);
      } else {
        goToPaymentScreen(table.id, orderIndex);
        clearSelectedTable();
      }
    },
    [
      showNotification,
      translate,
      setTableAction,
      goToPaymentScreen,
      clearSelectedTable,
    ],
  );

  const handleOnPressTransfer = useCallback(
    (table: Table, orderIndex: number) => {
      if (table) {
        let transferPayload = {};
        if (
          !transferSelections?.fromTable &&
          table.status &&
          table.status != TableStatus.AVAILABLE
        ) {
          closeAllNotifications();
          showNotification({
            info: true,
            message: translate('tableFloorView.transferToTableMessage', {
              tableNumber: table.name,
            }),
          });
          clearSelectedTable();
          transferPayload = {
            fromTable: table,
            toTable: undefined,
            fromOrderIndex: orderIndex ?? 0,
          };
        } else if (
          transferSelections?.fromTable &&
          transferSelections?.fromTable.id !== table.id &&
          typeof transferSelections?.fromOrderIndex === 'number' &&
          ![
            TableStatus.OCCUPIED,
            TableStatus.DONE_SOON,
            TableStatus.INACTIVE,
          ].includes(table.status)
        ) {
          transferTable({
            fromTable: transferSelections.fromTable,
            fromOrderIndex: transferSelections.fromOrderIndex,
            toTable: table,
          });
          transferPayload = { ...transferSelections, toTable: table };
        } else {
          showNotification({
            error: true,
            message: translate('tableFloorView.tableActionWarning'),
          });
          transferPayload = {};
        }
        setTransferSelections(transferPayload);
      }
    },
    [
      closeAllNotifications,
      showNotification,
      transferTable,
      translate,
      clearSelectedTable,
      setTransferSelections,
      transferSelections,
    ],
  );

  const mergeTable = useCallback(
    async (table: Table) => {
      const ordersToMerge = tableOrdersMap[table?.id || ''];

      // merging all orders with first order
      const firstOrder = ordersToMerge?.[0];
      const orderIdsToMerge = [] as string[];
      const ordersToBeMerged = [] as Order[];
      let orderItemsToMerge = [] as OrderItem[];
      let orderPaymentsToMerge = [] as OrderPayment[];
      ordersToMerge.forEach(eachOrder => {
        if (eachOrder?.id != firstOrder?.id) {
          orderIdsToMerge.push(eachOrder.id);
          orderItemsToMerge = orderItemsToMerge.concat(eachOrder.orderItems);
          ordersToBeMerged.push({ ...eachOrder, status: OrderStatus.MERGED });
          orderPaymentsToMerge = orderPaymentsToMerge.concat(
            eachOrder.payments,
          );
        }
      });

      clearPriorPendingEvents();
      await setCartParams(
        firstOrder?.id,
        firstOrder.orderType.id,
        firstOrder.table.id,
        true,
      );
      await updateCart(OrderAction.ORDER_INITIATE_MERGE, {
        orderIdsToMerge: orderIdsToMerge,
        itemMergeOptions: {
          orderItems: orderItemsToMerge,
          payments: orderPaymentsToMerge,
        },
      } as OrderInitiateMergeEvent);

      await updateCart(OrderAction.ORDER_SAVE);
      saveMergedOrders({ variables: { data: ordersToBeMerged } });

      delayRefreshTableDataMap.start(100);
    },
    [
      saveMergedOrders,
      clearPriorPendingEvents,
      delayRefreshTableDataMap,
      setCartParams,
      tableOrdersMap,
      updateCart,
    ],
  );

  const destinationTableOnMerge = useCallback(
    async (table: Table) => {
      const destinationTableOrders = tableOrdersMap[table.id];
      const sourceTableOrders =
        tableOrdersMap[mergeTableOption?.fromTable?.id as string];

      // merging all orders with first order
      const destinationTableOrder = destinationTableOrders?.[0];
      const orderIdsToMerge = sourceTableOrders.map(order => order.id);
      const ordersToBeMerged = sourceTableOrders.map(order => ({
        ...order,
        status: OrderStatus.MERGED,
      }));
      /**
       * Removing duplicate order items if any
       * This is occurring rarely when multi table orders merge happens
       *
       * Counting only 1 of the duplicate items should make sense, as it's payload remains same.
       */
      const orderItemsToMerge = uniqBy(
        sourceTableOrders.map(order => order.orderItems).flat(),
        'id',
      );

      const orderPaymentsToMerge = sourceTableOrders
        .map(order => order.payments)
        .flat();
      const orderAdjustmentsToMerge = sourceTableOrders
        .map(order => (order.adjustments ?? []) as Adjustment[])
        .flat();

      clearPriorPendingEvents();
      await setCartParams(
        destinationTableOrder.id,
        destinationTableOrder.orderType.id,
        destinationTableOrder.table.id,
        true,
      );
      updateCart<OrderInitiateMergeEvent>(OrderAction.ORDER_INITIATE_MERGE, {
        orderIdsToMerge: orderIdsToMerge,
        itemMergeOptions: {
          orderItems: orderItemsToMerge,
          payments: orderPaymentsToMerge,
          adjustments: orderAdjustmentsToMerge,
        },
      });
      updateCart(OrderAction.ORDER_SAVE);

      saveMergedOrders({ variables: { data: ordersToBeMerged } });
      setMergeTableOption({});
    },
    [
      tableOrdersMap,
      mergeTableOption?.fromTable?.id,
      clearPriorPendingEvents,
      setCartParams,
      updateCart,
      saveMergedOrders,
      setMergeTableOption,
    ],
  );

  const onConfirmMergeOrdersOnTable = useCallback(
    async (table: Table) => {
      setMergeTableOption({
        fromTable: table,
      });
      await mergeTable(table);
    },
    [mergeTable, setMergeTableOption],
  );

  const handleOnPressMerge = useCallback(
    (table: Table) => {
      if (table) {
        const ordersToMerge = tableOrdersMap[table?.id || ''];

        if (!ordersToMerge) {
          showNotification({
            message: translate('tableFloorView.allowedTableMerge', {
              tableName: table.name,
            }),
            error: true,
          });
          return;
        }
        if (ordersToMerge?.length <= 1) {
          // handle cross table merge
          if (!mergeTableOption?.fromTable?.id) {
            showNotification({
              message: translate('tableFloorView.merge2TableActionMessage', {
                tableName: table.name,
              }),
              info: true,
            });
            setMergeTableOption({ fromTable: table });
          } else if (mergeTableOption?.fromTable?.id === table.id) {
            setMergeTableOption({});
          } else {
            if (
              !MERGEABLE_TABLE_STATUS_FOR_2.includes(tableStatusMap[table.id])
            ) {
              showNotification({
                message: translate('tableFloorView.allowedTableMerge', {
                  tableName: table.name,
                }),
                error: true,
              });
              return;
            }
            showModal(
              <ConfirmationModal
                type="focus"
                title={translate('tableFloorView.merge2TableModalTitle', {
                  originTable: mergeTableOption.fromTable.name,
                  destinationTable: table.name,
                })}
                confirmLabel={translate(
                  'tableFloorView.merge2TableActionBtnTitle',
                )}
                message={translate('tableFloorView.mergeTableModalInfo')}
                onConfirm={() => destinationTableOnMerge(table)}
              />,
            );
          }
          return;
        }

        // handle single table merge
        if (mergeTableOption?.fromTable?.id === table.id) {
          setMergeTableOption({});
        } else if (mergeTableOption?.fromTable?.id !== table.id) {
          showModal(
            <ConfirmationModal
              title={translate('tableFloorView.mergeTableModalTitle')}
              confirmLabel={translate(
                'tableFloorView.mergeTableActionBtnTitle',
              )}
              message={translate('tableFloorView.mergeTableModalInfo')}
              onConfirm={() => onConfirmMergeOrdersOnTable(table)}
            />,
          );
        }
      }
    },
    [
      destinationTableOnMerge,
      mergeTableOption?.fromTable,
      onConfirmMergeOrdersOnTable,
      setMergeTableOption,
      showModal,
      showNotification,
      tableStatusMap,
      tableOrdersMap,
      translate,
    ],
  );

  const navigateToTakeOrder = useCallback(
    (table: Table, orderIndex: number) => {
      const orders = tableOrdersMap[table.id];
      if (orders?.[orderIndex]) {
        navigation.navigate('TakeOrder', {
          id: orders?.[orderIndex].id,
          isCompleted: false,
          isExisting: true,
        });
        clearSelectedTable();
        openOrderCart(orders?.[orderIndex].id);
      }
      if (
        (!table.status || table.status === TableStatus.AVAILABLE) &&
        deviceProfile?.promptGuestCount
      ) {
        showSetGuestModal(table);
      } else if (!table.status || table.status === TableStatus.AVAILABLE) {
        onSubmitTable(undefined, table);
      }
    },
    [
      tableOrdersMap,
      deviceProfile?.promptGuestCount,
      navigation,
      clearSelectedTable,
      openOrderCart,
      showSetGuestModal,
      onSubmitTable,
    ],
  );

  const showStatsForTable = useCallback(
    (table: Table, orderIndex: number) => {
      if (
        table.status &&
        (table.status === TableStatus.DONE_SOON ||
          table.status === TableStatus.OCCUPIED)
      ) {
        setSelectedTable(table);
        setSelectedOrderId(tableOrdersMap[table.id]?.[orderIndex]?.id);
        showStatsTablePanel(true);
      }
    },
    [setSelectedTable, showStatsTablePanel, tableOrdersMap, setSelectedOrderId],
  );

  const initiateTransferItems = useCallback(() => {
    setIsTransferItemsVisible(true);
  }, [setIsTransferItemsVisible]);

  const onClickTable = useCallback(
    (table: Table, orderIndex?: number) => {
      const tableOrders = tableOrdersMap[table?.id];
      if (tableAction === TableAction.TRANSFER && isTransferItemsEnabled) {
        if (!!tableOrders?.length) {
          setSelectedTable(table);
          initiateTransferItems();
        } else {
          showNotification({
            error: true,
            message: translate('tableFloorView.tableActionWarning'),
          });
        }
      } else {
        const isMultiOrderTable = tableOrders?.length > 1;
        if (isMultiOrderTable && orderIndex === undefined) {
          if (
            tableAction === TableAction.TRANSFER &&
            transferSelections?.fromTable
          ) {
            handleOnPressTransfer(table, orderIndex ?? 0);
          } else {
            setSelectedTable(table);
          }

          if (tableAction === TableAction.MERGE) {
            handleOnPressMerge(table);
            return;
          }
          return;
        }

        switch (tableAction) {
          case TableAction.SHOW_TABLE_STATS:
            showStatsForTable(table, orderIndex ?? 0);
            break;
          case TableAction.PRINT:
            handleOnPressPrint(table, orderIndex ?? 0);
            break;
          case TableAction.PAY:
            const allowPay = canI([{ onResource: Resource.FINISH_SALE }], {
              prompt: true,
            });
            if (allowPay) {
              handleOnPressPay(table, orderIndex ?? 0);
            }
            break;
          case TableAction.TRANSFER:
            handleOnPressTransfer(table, orderIndex ?? 0);
            break;
          case TableAction.MERGE:
            handleOnPressMerge(table);
            break;
          default:
            navigateToTakeOrder(table, orderIndex ?? 0);
            break;
        }
      }
    },
    [
      tableOrdersMap,
      tableAction,
      isTransferItemsEnabled,
      setSelectedTable,
      initiateTransferItems,
      showNotification,
      translate,
      transferSelections?.fromTable,
      handleOnPressTransfer,
      handleOnPressMerge,
      showStatsForTable,
      handleOnPressPrint,
      canI,
      navigateToTakeOrder,
      handleOnPressPay,
    ],
  );

  const onDragRelease = useCallback(
    (table: Table) => {
      setIsDragging(false);
      setDraggingTable(undefined);
      if (!unsavedTables) return;
      const tableIndex = unsavedTables.findIndex(
        (item: { id: string }) => item.id === table.id,
      );
      if (tableIndex > -1) {
        unsavedTables.splice(tableIndex, 1, table);
      } else {
        unsavedTables.push(table);
      }
      setUnsavedTables([...unsavedTables]);
      addExtraUnsavedGridTable(unsavedTables);
    },
    [setUnsavedTables, unsavedTables, addExtraUnsavedGridTable],
  );
  const onPressIn = useCallback(() => {
    setIsDragging(true);
  }, []);

  const onCreateNonDineInOrder = useCallback(
    async (orderTypeId: string) => {
      closeModal();
      setCartParams(undefined, orderTypeId, '', false);
      const newOrderId = await resetCart();

      navigation.navigate('TakeOrder', {
        id: newOrderId,
        orderType: orderTypeId,
        isExisting: false,
      });
    },
    [closeModal, resetCart, setCartParams, navigation],
  );

  const openOrderTypePicker = useCallback(() => {
    showModal(
      <PickerModal
        onSelect={onCreateNonDineInOrder}
        title={translate('order.changeOrderType')}
        options={nonDineInOrderTypes.map(x => ({
          label: x.name,
          value: x.id,
        }))}
      />,
    );
  }, [showModal, nonDineInOrderTypes, translate, onCreateNonDineInOrder]);

  const shouldUnselectTable = useCallback(
    (table: Table) => {
      switch (tableAction) {
        case TableAction.SHOW_TABLE_STATS:
          if (table.id !== selectedTable?.id && selectedTable) return true;
          return false;

        default:
          return false;
      }
    },
    [selectedTable, tableAction],
  );

  const shouldShowTableAsSelected = useCallback(
    (table: Table) => {
      switch (tableAction) {
        case TableAction.SHOW_TABLE_STATS:
          return table.id === selectedTable?.id;
        case TableAction.TRANSFER:
          return table.id === transferSelections?.fromTable?.id;
        case TableAction.MERGE:
          return table.id === mergeTableOption?.fromTable?.id;
        default:
          return false;
      }
    },
    [
      tableAction,
      selectedTable?.id,
      transferSelections?.fromTable?.id,
      mergeTableOption?.fromTable?.id,
    ],
  );

  const renderTableInLayout = useCallback(
    (table: Table, index: number) => (
      <DraggableTableIcon
        key={table.id}
        onRequestClose={clearSelectedTable}
        orders={tableOrdersMap[table.id]}
        table={table}
        onPressSubTable={onClickTable}
        index={index}
        onPressIn={onPressIn}
        onDragTable={setDraggingTable}
        onDragReleaseTable={onDragRelease}
        isDraggingTable={isDragging && table?.id === draggingTable?.id}
        highlight={shouldShowTableAsSelected(table)}
        blur={shouldUnselectTable(table)}
        onPressTable={onClickTable}
        disabled={isEditMode}
        displayAsGrid={isUnPositionedAllTables}
        tableAction={tableAction}
        isTransferItemsEnabled={isTransferItemsEnabled}
      />
    ),
    [
      clearSelectedTable,
      draggingTable?.id,
      isDragging,
      isEditMode,
      onClickTable,
      onDragRelease,
      onPressIn,
      shouldShowTableAsSelected,
      shouldUnselectTable,
      tableOrdersMap,
      isUnPositionedAllTables,
      tableAction,
      isTransferItemsEnabled,
    ],
  );

  return (
    <>
      <View testID="floor-view" style={{ height: safeHeight }}>
        <PanView>
          <MemoListTableIcons
            list={positionedTables}
            renderer={renderTableInLayout}
          />
          <MemoListTableIcons
            list={unPositionedAllTables}
            renderer={renderTableInLayout}
          />
        </PanView>
        {!isEditMode ? (
          <View style={styles.footer}>
            <FloorViewActions onPressNewOrder={openOrderTypePicker} />
            <FloorViewLegend
              tables={allTables}
              inProgressOrders={inProgressOrdersForSection}
              tableToOrdersMap={tableOrdersMap}
            />
          </View>
        ) : null}
      </View>
    </>
  );
};

export default SectionView;
