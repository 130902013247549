import React, { createContext, useContext, useMemo } from 'react';
import {
  Shift,
  Order,
  OrderItem,
  MoneyMovement,
  Printer,
  Product,
  OrderTransferItemsEvent,
} from '@oolio-group/domain';
import { usePrintingWithTemplate } from './app/usePrintingWithTemplate';
import { Notification } from './Notification';

export interface PrintingProviderProps {
  children: React.ReactNode;
}

export interface PrintingContextValue {
  printBill: (
    order: Order,
    nthPaymentToPrint?: number,
  ) => Promise<Notification | void>;
  printRecipe: (product: Product) => Promise<Notification | void>;
  reprintKitchenDocket: (
    order: Order,
    orderItems: OrderItem[],
  ) => Promise<Notification | void>;
  printMoneyMovementReceipt: (
    moneyMovement: MoneyMovement,
  ) => Promise<Notification | void>;
  printShiftReceipt: (shift: Shift) => Promise<Notification | void>;
  printTestTemplate: (
    printer: Printer,
    template?: string,
  ) => Promise<Notification | void>;
  openCashDrawer: () => Promise<Notification | void>;
  printTransferItemsActionSummary: (
    event: OrderTransferItemsEvent,
    tableNamesMap: Record<string, string>,
  ) => Promise<Notification | void>;
}

export const PrintingContext = createContext<PrintingContextValue>(
  {} as PrintingContextValue,
);

export const PrintingProvider: React.FC<PrintingProviderProps> = ({
  children,
}: PrintingProviderProps) => {
  const {
    printBill,
    reprintKitchenDocket,
    printShiftReceipt,
    printTestTemplate,
    printMoneyMovementReceipt,
    openCashDrawer,
    printRecipe,
    printTransferItemsActionSummary,
  } = usePrintingWithTemplate();

  const value = useMemo(
    () => ({
      printBill,
      reprintKitchenDocket,
      printShiftReceipt,
      printTestTemplate,
      printMoneyMovementReceipt,
      openCashDrawer,
      printRecipe,
      printTransferItemsActionSummary,
    }),
    [
      printBill,
      reprintKitchenDocket,
      printShiftReceipt,
      printTestTemplate,
      printMoneyMovementReceipt,
      openCashDrawer,
      printRecipe,
      printTransferItemsActionSummary,
    ],
  );

  return (
    <PrintingContext.Provider value={value}>
      {children}
    </PrintingContext.Provider>
  );
};

export function usePrinting(): PrintingContextValue {
  return useContext(PrintingContext);
}
