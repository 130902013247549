import React, { FC, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { translate } from '@oolio-group/localization';
import { useNotification } from '../../../../../../hooks/Notification';
import useBehaviorSubjectState from '../../../../../../hooks/app/useSubjectState';
import {
  tableActionController,
  sectionStatsVisibilityController,
  TableAction,
  mergeTableSelectionsController,
} from '../floorViewObservables';
import { analyticsService } from '../../../../../../analytics/AnalyticsService';
import styles from '../../FloorView.styles';
import theme from '../../../../../../common/default-theme';
import Icon from '../../../../../../components/Icon/Icon';
import TreatButton from '../../../../../../components/Shared/TreatButton/TreatButton';
import { useReactiveVar } from '@apollo/client/react/hooks/useReactiveVar';
import { isReservationsEnabledVar } from '../../../../../../state/cache';
import { useNavigation } from '@react-navigation/native';
import { Resource } from '@oolio-group/domain';
import usePOSUserAuthorization from '../../../../../../hooks/app/users/usePOSUserAuthorization';

interface FloorViewActionsProps {
  onPressNewOrder: () => void;
}

type TableActionProps = {
  testID?: string;
  name: string;
  icon?: boolean;
  isActive: boolean;
  action: () => void;
};

const TableActionButton: FC<TableActionProps> = ({
  testID,
  name,
  icon,
  isActive,
  action,
}) => {
  const bgColor = isActive ? theme.colors.accent : theme.colors.white;
  const fgColor = isActive ? theme.colors.white : theme.colors.dark;

  const content = icon ? (
    <Icon name={name || 'info-circle'} size={20} color={fgColor} />
  ) : (
    <Text style={[styles.actionText, { color: fgColor }]}>{name}</Text>
  );

  return (
    <TouchableOpacity
      testID={testID}
      onPress={action}
      style={[styles.btnAction, { backgroundColor: bgColor }]}
    >
      {content}
    </TouchableOpacity>
  );
};

const FloorViewActions: React.FC<FloorViewActionsProps> = ({
  onPressNewOrder,
}) => {
  const { showNotification } = useNotification();
  const { navigate } = useNavigation();

  const isReservationsEnabled = useReactiveVar(isReservationsEnabledVar);

  const { value: isSectionStatsPanelVisible, setValue: setSectionStatsPanel } =
    useBehaviorSubjectState<boolean>(sectionStatsVisibilityController);
  const { value: tableAction, setValue: setTableAction } =
    useBehaviorSubjectState<TableAction>(tableActionController);
  const { canI } = usePOSUserAuthorization();

  const onPressInfo = useCallback(() => {
    analyticsService.capture('floorview_action', {
      button: 'Info',
    });
    if (tableAction === TableAction.SHOW_TABLE_STATS) {
      setTableAction(TableAction.DEFAULT);
    } else {
      setTableAction(TableAction.SHOW_TABLE_STATS);
    }
  }, [setTableAction, tableAction]);

  const onPressPrint = useCallback(() => {
    analyticsService.capture('floorview_action', {
      button: 'Print',
    });
    if (tableAction === TableAction.PRINT) {
      setTableAction(TableAction.DEFAULT);
    } else {
      setTableAction(TableAction.PRINT);
    }
  }, [setTableAction, tableAction]);

  const onPressPay = useCallback(() => {
    analyticsService.capture('floorview_action', {
      button: 'Pay',
    });
    if (tableAction === TableAction.PAY) {
      setTableAction(TableAction.DEFAULT);
    } else {
      setTableAction(TableAction.PAY);
    }
  }, [setTableAction, tableAction]);

  const onPressSectionStats = useCallback(() => {
    analyticsService.capture('floorview_action', {
      button: 'Stats',
    });
    setTableAction(TableAction.DEFAULT);
    setSectionStatsPanel(true);
  }, [setSectionStatsPanel, setTableAction]);

  const onPressReservations = useCallback(() => {
    analyticsService.capture('floorview_action', {
      button: 'Reservations',
    });
    navigate('Reservations', {});
  }, [navigate]);

  const onPressTransfer = useCallback(() => {
    analyticsService.capture('floorview_action', {
      button: 'Transfer',
    });
    const hasAccess = canI([{ onResource: Resource.TRANSFER_ORDER }], {
      prompt: true,
    });
    if (!hasAccess) return;
    if (tableAction === TableAction.TRANSFER) {
      setTableAction(TableAction.DEFAULT);
    } else {
      setTableAction(TableAction.TRANSFER);
      showNotification({
        info: true,
        message: translate('tableFloorView.transferTableMessage'),
      });
    }
  }, [canI, setTableAction, showNotification, tableAction]);

  const onPressMerge = useCallback(() => {
    analyticsService.capture('floorview_action', {
      button: 'Merge',
    });
    if (tableAction === TableAction.MERGE) {
      setTableAction(TableAction.DEFAULT);
    } else {
      setTableAction(TableAction.MERGE);
      mergeTableSelectionsController.next({});
      showNotification({
        info: true,
        message: translate('tableFloorView.mergeTableActionMessage'),
      });
    }
  }, [setTableAction, showNotification, tableAction]);

  const onPressTakeOrder = useCallback(() => {
    analyticsService.capture('btn_new_order', {
      location: 'Floor View',
    });
    onPressNewOrder();
  }, [onPressNewOrder]);

  return (
    <View style={styles.actions}>
      <View style={styles.actionGroup}>
        <TableActionButton
          testID="btn-print"
          name={translate('tableFloorView.print')}
          action={onPressPrint}
          isActive={tableAction === TableAction.PRINT}
        />
        <TableActionButton
          testID="btn-pay"
          name={translate('tableFloorView.pay')}
          action={onPressPay}
          isActive={tableAction === TableAction.PAY}
        />
        <TableActionButton
          testID="btn-info"
          name={translate('tableFloorView.info')}
          action={onPressInfo}
          isActive={tableAction === TableAction.SHOW_TABLE_STATS}
        />
      </View>
      <View style={styles.actionGroup}>
        <TableActionButton
          testID="btn-transfer"
          name={translate('tableFloorView.transfer')}
          action={onPressTransfer}
          isActive={tableAction === TableAction.TRANSFER}
        />
        <TableActionButton
          testID="btn-merge"
          name={translate('tableFloorView.merge')}
          action={onPressMerge}
          isActive={tableAction === TableAction.MERGE}
        />
      </View>
      <View style={styles.actionGroup}>
        <TableActionButton
          icon
          testID="btn-stats"
          name="chart"
          action={onPressSectionStats}
          isActive={isSectionStatsPanelVisible || false}
        />
      </View>
      <View>
        <TreatButton
          type="positive"
          testID="btn-newOrder"
          label={translate('tableFloorView.newOrder')}
          onPress={onPressTakeOrder}
        />
      </View>
      {isReservationsEnabled && (
        <View>
          <TableActionButton
            icon
            testID="btn-stats"
            name="calendar-alt"
            action={onPressReservations}
            isActive={isSectionStatsPanelVisible || false}
          />
        </View>
      )}
    </View>
  );
};

export default FloorViewActions;
