import React, { useCallback, useMemo, useState } from 'react';
import { Table } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useRoute } from '@react-navigation/core';
import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps,
} from '@react-navigation/material-top-tabs';
import { RouteProp, useNavigation } from '@react-navigation/native';
import { useTables } from '../../../../hooks/app/tables/useTables';
import { useSession } from '../../../../hooks/app/useSession';
import useBehaviorSubjectState from '../../../../hooks/app/useSubjectState';
import { AppScreen } from '../../../../types/AppScreen';
import {
  editModeController,
  unsavedTableController,
  sectionIdController,
} from './Sections/floorViewObservables';
import SectionTabs from './Sections/SectionTabs/SectionTabs';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import SectionView from './Sections/SectionsView';
import TableInfoSidePanel from './SidePanels/TableInfoSidePanel';
import SectionStatsPanel from './SidePanels/SectionStatsSidePanel';
import TransferItemsSidePanel from './SidePanels/TransferItemsSidePanel';
import ScreenLayout from '../../../../components/POS/ScreenLayout/ScreenLayout';
import ConfirmationModal from '../../../../components/Modals/ConfirmationDialog';
import LoadingOverlay from '../../../../components/Shared/Loaders/LoadingOverlay';
import OrdersSegmentTabs from '../OrdersSegmentTabs';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';

type FloorOrdersParamList = RouteProp<
  {
    FloorView: { previousScreen: string };
  },
  'FloorView'
>;

const Tab = createMaterialTopTabNavigator();

const FloorView: React.FC = () => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const [lastRollbackKey, setLastRollbackKey] = useState(Date.now());
  const { value: isEditMode, setValue: setIsEditMode } =
    useBehaviorSubjectState(editModeController);
  const { value: unsavedTables, setValue: setUnsavedTables } =
    useBehaviorSubjectState<Table[]>(unsavedTableController);
  const { value: sectionId } =
    useBehaviorSubjectState<string>(sectionIdController);
  const route = useRoute<FloorOrdersParamList>();
  const { previousScreen } = route.params ?? {};
  const { showModal, closeModal } = useModal();
  const [{ deviceProfile }] = useSession();
  const { updateTables, loading } = useTables({
    sectionId,
  });

  const onPressEdit = useCallback(() => {
    analyticsService.capture('floorview_layout', {
      event: 'open',
    });
    setIsEditMode(!isEditMode);
    setUnsavedTables([]);
  }, [setUnsavedTables, setIsEditMode, isEditMode]);

  const onDiscardChanges = useCallback(() => {
    analyticsService.capture('floorview_layout', {
      event: 'discarded',
    });
    setIsEditMode(false);
    setUnsavedTables([]);
    setLastRollbackKey(Date.now());
  }, [setUnsavedTables, setIsEditMode]);

  const onPressSave = useCallback(async () => {
    analyticsService.capture('floorview_layout', {
      event: 'edited',
    });
    if (!unsavedTables || unsavedTables.length === 0) {
      closeModal();
      setIsEditMode(false);
      return;
    }
    const updateTablesInput = unsavedTables.map(table => ({
      id: table.id,
      position: table.position,
      section: table.section?.id,
    }));
    await updateTables(updateTablesInput);
    setIsEditMode(false);
    closeModal();
  }, [closeModal, updateTables, unsavedTables, setIsEditMode]);

  const onCancelEdit = useCallback(() => {
    setIsEditMode(false);
    if (unsavedTables?.length && unsavedTables.length > 0) {
      showModal(
        <ConfirmationModal
          title={translate('floorView.discardPositionChangeTitle')}
          message={translate('floorView.discardPositionChangeDescription')}
          onConfirm={onPressSave}
          confirmLabel={translate('button.save')}
          onCancel={onDiscardChanges}
          cancelLabel={translate('button.discard')}
        />,
      );
    }
  }, [
    setIsEditMode,
    unsavedTables?.length,
    showModal,
    onPressSave,
    onDiscardChanges,
    translate,
  ]);

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const SectionLayouts = useMemo(() => {
    if (deviceProfile?.sections?.length) {
      return deviceProfile.sections.map(
        (section: { name: string; id: string }) => (
          <Tab.Screen
            name={section.name}
            key={section.id}
            initialParams={{
              id: section.id,
            }}
            component={SectionView}
          />
        ),
      );
    }
    return [];
  }, [deviceProfile]);

  return (
    <ScreenLayout
      title={translate('floorView.editSectionLayout')}
      hideRHS={isEditMode}
      scrollEnabled={false}
      tabs={
        !isEditMode ? (
          <OrdersSegmentTabs
            previousScreen={previousScreen}
            activeScreen={AppScreen.FLOOR_VIEW}
          />
        ) : undefined
      }
      onBack={!isEditMode ? () => onPressBack() : undefined}
      actionsLeft={
        isEditMode ? (
          <ButtonIcon
            icon="times"
            testID="btn-cancelEdit"
            size={44}
            type="negative"
            onPress={onCancelEdit}
          />
        ) : undefined
      }
      actionsRight={
        <ButtonIcon
          size={44}
          icon={isEditMode ? 'check' : 'pen'}
          type={isEditMode ? 'positive' : 'interface'}
          testID={isEditMode ? 'btn-save' : 'btn-edit'}
          onPress={isEditMode ? onPressSave : onPressEdit}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{ marginRight: isEditMode ? 0 : 6 }}
        />
      }
    >
      <LoadingOverlay isLoading={loading} />
      <>
        {SectionLayouts.length > 0 ? (
          <Tab.Navigator
            key={`${lastRollbackKey}`}
            swipeEnabled={false}
            tabBar={(props): React.ReactNode => (
              <SectionTabs tabBar={props as MaterialTopTabBarProps} />
            )}
          >
            {SectionLayouts}
          </Tab.Navigator>
        ) : null}
      </>
      <TableInfoSidePanel />
      <SectionStatsPanel />
      <TransferItemsSidePanel />
    </ScreenLayout>
  );
};

export default FloorView;
