import { Order, OrderAction, OrderEvent } from '@oolio-group/domain';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import { GET_ORDER_FRAGMENT } from '../hooks/app/orders/graphql';
import { getApolloCache } from './apolloClient';
import { analyticsService } from '../analytics/AnalyticsService';

type SourceOptionsAttrs =
  | 'organizationId'
  | 'venueId'
  | 'storeId'
  | 'deviceId'
  | 'triggeredBy';

type SourceOptions = Record<SourceOptionsAttrs, string>;

const SOURCE_OPTIONS_ATTRS: SourceOptionsAttrs[] = [
  'organizationId',
  'venueId',
  'storeId',
  'deviceId',
  'triggeredBy',
];

export const getOrderFragmentFromCache = (orderId: string) => {
  const cache = getApolloCache();
  const cachedOrder = cache.readFragment<Order>({
    id: `Order:${orderId}`,
    fragment: GET_ORDER_FRAGMENT,
  });
  return cachedOrder;
};

/**
 * Generate event object of type T which extends OrderEvent
 * helps to quickly create an order event by specifying only -
 * properties necessary for the event excluding all props from OrderEvent
 * @param action OrderAction eg: OrderAction.ORDER_INITIATE
 * @param session Session object, throws exception if invalid
 * @param input Props specific to event type
 * @param shouldPersisted optional prop to chose to persist the event to db
 */

let currentTimeStamp = 0;

export function generateOrderEvent<T extends OrderEvent>(
  action: OrderAction,
  sourceOptions: Partial<SourceOptions>,
  input?: Omit<T, keyof OrderEvent> & {
    orderId?: string;
    previous?: string;
    source?: string;
  },
  eventId?: string,
): OrderEvent {
  if (typeof action !== 'string' || typeof sourceOptions !== 'object') {
    throw new Error(
      'Expected at least two arguments of type OrderAction and SourceOptions',
    );
  }

  if (input && typeof input !== 'object') {
    throw new Error(
      'Expected three arguments of type OrderAction, Session and OrderEvent',
    );
  }

  if (
    !SOURCE_OPTIONS_ATTRS.filter(attr => attr !== 'triggeredBy').every(
      attr => sourceOptions[attr] && typeof sourceOptions[attr] !== 'undefined',
    )
  ) {
    throw new Error('Invalid session information');
  }

  let timestamp = new Date().getTime();
  if (timestamp <= currentTimeStamp) {
    timestamp = currentTimeStamp + 1;
  }
  currentTimeStamp = timestamp;

  const orderId = input?.orderId || uuidv4();

  const event = {
    ...(input || {}),
    action,
    id: eventId || uuidv4(),
    timestamp,
    orderId: orderId,
    ...(SOURCE_OPTIONS_ATTRS.reduce(
      (acc, attr) => ({ ...acc, [attr]: sourceOptions[attr] }),
      {},
    ) as Record<SourceOptionsAttrs, string>),
  } as OrderEvent;

  if (action === OrderAction.ORDER_INITIATE) {
    analyticsService.capture('order_created', {
      orderId: orderId,
    });
  }

  return event;
}
