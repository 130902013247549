import { WorkerAction, WorkerActionHandlers } from '../utils';
import printHandler from './printHandler';

const workerHandlers: WorkerActionHandlers = {
  [WorkerAction.PRINT]: printHandler,
  [WorkerAction.PRINT_BILL]: printHandler,
  [WorkerAction.PRINT_RECIPE]: printHandler,
  [WorkerAction.PRINT_KITCHEN_DOCKET]: printHandler,
  [WorkerAction.PRINT_MONEY_MOVEMENT]: printHandler,
  [WorkerAction.PRINT_SHIFT]: printHandler,
  [WorkerAction.PRINT_RESEND_KITCHEN_DOCKET]: printHandler,
  [WorkerAction.PRINT_OPEN_CASH_DRAWER]: printHandler,
  [WorkerAction.PRINT_TEST_RECEIPT]: printHandler,
  [WorkerAction.PRINT_TRANSFER_ITEMS_SUMMARY]: printHandler,
};
export default workerHandlers;
