import {
  Device,
  MoneyMovement,
  Order,
  OrderItem,
  OrderTransferItemsEvent,
  Printer,
  PrinterType,
  Product,
  Shift,
} from '@oolio-group/domain';
import { DocketType } from '../hooks/app/usePrintingWithTemplate';
import { Session } from '../state/Session';
import { PrinterTemplateMapping } from '../utils/printerTemplates/printingDataUtils';

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum WorkerAction {
  // print actions
  PRINT = 'PRINT',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  PRINT_BILL = 'PRINT_BILL',
  PRINT_RECIPE = 'PRINT_RECIPE',
  PRINT_KITCHEN_DOCKET = 'PRINT_KITCHEN_DOCKET',
  PRINT_MONEY_MOVEMENT = 'PRINT_MONEY_MOVEMENT',
  PRINT_SHIFT = 'PRINT_SHIFT',
  PRINT_RESEND_KITCHEN_DOCKET = 'PRINT_RESEND_KITCHEN_DOCKET',
  PRINT_OPEN_CASH_DRAWER = 'PRINT_OPEN_CASH_DRAWER',
  PRINT_TRANSFER_ITEMS_SUMMARY = 'PRINT_TRANSFER_ITEMS_SUMMARY',
  PRINT_TEST_RECEIPT = 'PRINT_TEST_RECEIPT',
}

export enum WorkerActionResultStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type WorkerActionHandlers = {
  [action: string]: (message: WorkerInput) => Promise<WorkerActionResult[]>;
};

export interface WorkerActionResult {
  requestId?: string;
  status?: WorkerActionResultStatus;
  data?: WorkerActionResultData;
  message?: string;
  action?: WorkerAction;
  timestamp?: number;
  processed?: boolean;
}

// can add other types as union
type WorkerActionResultData =
  | PrintWorkerActionData
  | PushNotificationWorkerActionData;

export interface PrintWorkerActionData {
  buffer?: Buffer;
  printer?: WebPrinter;
  order?: Order;
}

export interface PushNotificationWorkerActionData {
  orderEvent?: string;
}

export interface WorkerInput {
  action: WorkerAction;
  requestId?: string;
  // can add other types as union
  data: PrintWorkerInput;
  priority?: number;
}

type PrintWorkerInput =
  | PrintWorkerInputBase
  | PrintBillWorkerInput
  | PrintCashDrawerWorkerInput
  | PrintKitchenDocketWorkerInput
  | PrintMoneyMovementWorkerInput
  | PrintShiftWorkerInput
  | PrintTestTemplateWorkerInput
  | PrintReprintKitchenDocketWorkerInput
  | PrintTransferItemsSummaryDocketWorkerInput
  | PrintRecipeWorkerInput;

export interface PrintWorkerInputBase {
  bufferObjs?: {
    buffer: Buffer;
    printer: WebPrinter;
  }[];
}

export interface PrintRecipeWorkerInput extends PrintWorkerInputBase {
  product: Product;
  printerTemplateMapping: PrinterTemplateMapping;
}

export interface PrintBillWorkerInput extends PrintWorkerInputBase {
  order: Order;
  printerTemplateMapping: PrinterTemplateMapping;
  session: Session;
  currency: string;
  nthPaymentToPrint?: number;
}
export interface PrintCashDrawerWorkerInput extends PrintWorkerInputBase {
  printerTemplateMapping: PrinterTemplateMapping;
}
export interface PrintKitchenDocketWorkerInput extends PrintWorkerInputBase {
  order: Order;
  printerTemplateMapping: PrinterTemplateMapping;
  session: Session;
  printItems: OrderItem[];
  translatedNowCourse: string;
}
export interface PrintMoneyMovementWorkerInput extends PrintWorkerInputBase {
  moneyMovement: MoneyMovement;
  printerTemplateMapping: PrinterTemplateMapping;
  currency: string;
}
export interface PrintShiftWorkerInput extends PrintWorkerInputBase {
  shift: Shift;
  printerTemplateMapping: PrinterTemplateMapping;
  session: Session;
  currency: string;
}
export interface PrintTestTemplateWorkerInput extends PrintWorkerInputBase {
  session: Session;
  printer: Printer;
  devices: Device[];
  template?: string;
}

export interface PrintTransferItemsSummaryDocketWorkerInput
  extends PrintWorkerInputBase {
  session: Session;
  printerTemplateMapping: PrinterTemplateMapping;
  order: Order;
  event: OrderTransferItemsEvent;
  tableNamesMap: Record<string, string>;
}

export interface PrintReprintKitchenDocketWorkerInput
  extends PrintWorkerInputBase {
  order: Order;
  orderItems: OrderItem[];
  printerTemplateMapping: PrinterTemplateMapping;
  session: Session;
  currency: string;
  printType: DocketType;
}

// buffer handlers
export type BufferHandlers = {
  [action: string]: (message: WorkerInput) => BufferHandlerResult[];
};

export interface WebPrinter extends Partial<Printer> {
  device_name: string;
  host?: string;
  type: PrinterType;
  id: string;
  port: number;
}

export interface BufferHandlerResult {
  printer: WebPrinter;
  buffer: Buffer;
}
export const DEFAULT_NET_PRINTER_PORT = 9100;
